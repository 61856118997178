import {get, post, put, Delete} from '../axios'

/* ============== 省市区接口 start================= */
export const getCity = params => get(`/applet/tool/get/area/${params}`);
/* ============== 省市区接口 start================= */

/* ============== 重置密码 start================= */
export const passwordUpdate = params => put('/web/index/user/password/update',params);
/* ============== 重置密码 start================= */

/* ==============基础设置管理 start================= */

/* banner-列表 */
export const bannerList = params => get('/platform/banner/list',params);

/* banner-新增 */
export const bannerAdd = params => post('/platform/banner/add',params);

/* banner-修改 */
export const bannerUpdate = params => put('/platform/banner/update',params);

/* banner-删除 */
export const bannerDelete = params => Delete(`/platform/banner/delete/${params}`);

/* 首页管理 - 列表 */
export const indexList = params => get('/platform/index/list',params);

/* 首页管理 - 设为首页 */
export const indexSethoemshow = params => put('/platform/index/sethoemshow',params);

/* 首页管理 - 添加页面 */
export const indexAdd = params => post('/platform/index/add',params);

/* 首页管理 - 修改页面 */
export const indexUpdate = params => put('/platform/index/update',params);

/* 首页管理 - 复制页面 */
export const indexCopy = params => put(`/platform/index/copy/${params}`);

/* 首页管理 - 删除页面 */
export const indexDelete = params => Delete(`/platform/index/delete/${params}`);

/* 首页管理 - 获取页面详情 */
export const indexDetail = params => get(`/platform/index/detail/${params}`);

/* 首页管理 - 保存页面详情 */
export const indexDetailUpdate = params => put('/platform/index/detail/update',params);

/* 首页管理 - 查看二维码 */
export const indexPreview = params => get('/platform/index/preview',params);

/* ==============基础设置管理 end================= */

/* ==============商家区管理 start================= */

/* 商家区-列表 */
export const storeList = params => get('/platform/store/list',params);

/* 商家区-新增 */
export const storeAdd = params => post('/platform/store/add',params);

/* 商家区-修改 */
export const storeUpdate = params => put('/platform/store/update',params);

/* 商家区-删除 */
export const storeDelete = params => Delete(`platform/store/delete/${params}`);

/* ==============商家区管理 end================= */

/* ==============供应商 start================= */

/* 供应商-列表 */
export const supplierList = params => get('/platform/supplier/list',params);

/* 供应商-新增 */
export const supplierAdd = params => post('/platform/supplier/add',params);

/* 供应商-修改 */
export const supplierUpdate = params => put('/platform/supplier/update',params);

/* 供应商-冻结/恢复/取消入驻 */
export const supplierDelete = params => put(`/platform/supplier/${params}`);


/* 供应商-审核列表 */
export const supplierAuditList = params => get('/platform/supplier/audit/list',params);

/* 供应商-审核 */
export const supplierAudit = params => put(`/platform/supplier/audit/${params}`);

/* 供应商- 供应商对账结算列表 */
export const supplierorderList = params => get('/platform/supplier/order/list',params);

/* 供应商- 对账订单详情 */
export const supplierorderDetail = params => get('/platform/supplier/order/detail',params);

/* ==============供应商管理 end================= */

/* ==============分账管理 start================= */

/* 分账管理-列表 */
export const splitaccountList = params => get('/platform/splitaccount/list',params);

/* 分账管理-添加配置 */
export const splitaccountAdd = params => post('/platform/splitaccount/add',params);

/* 分账管理-修改 */
export const splitaccountUpdate = params => put('/platform/splitaccount/update',params);

/* 分账管理-删除 */
export const splitaccountDelete = params => Delete(`/platform/splitaccount/delete/${params}`);

/* 分账管理供应商-列表 */
export const splitaccountsupplierList = params => get('/platform/splitaccountsupplier/list',params);

/* 分账管理手动分账-列表 */
export const supplierOrderList = params => get('/platform/supplier/order/point/manual/list',params);

/* 分账管理手动分账-分账 */
export const supplierOrderSplit = params => put('/platform/supplier/order/point/split',params);

/* 分账管理手动分账-对账管理 */
export const managementList = params => get('/platform/supplier/order/point/reconciliation/list',params);

/* 分账管理分账审核-列表*/
export const splitaccountAuditList = params => get('/platform/supplier/order/point/split/check/list',params);

/* 分账管理分账审核-审核*/
export const splitaccountAuditSplit = params => put('/platform/supplier/order/point/split/check',params);

/* ==============分账管理 end================= */


/* ============== 企业会员管理 start================= */

/* 企业会员列表-审核*/
export const enterprisememberList = params => get('/platform/enterprisemember/list',params);

/* 企业会员列表-查看员工*/
export const enterprisememberListLow = params => get('/platform/emember/list',params);

/* 企业会员列表-冻结/恢复/取消入驻*/
export const enterprisememberOperateDelete = params => put(`/platform/enterprisememberOperate/${params}`);

/* 企业会员列表-添加企业会员*/
export const enterprisememberAdd = params => post('/platform/enterprisemember/add',params);

/* 企业会员列表- 修改*/
export const enterprisemembereditUpdate = params => put('/platform/enterprisememberedit/update',params);

/* 企业会员审核-列表*/
export const enterprisemembercheckList = params => get('/platform/enterprisemembercheck/list',params);

/* 企业会员审核-修改*/
export const enterprisemembercheckUpdate  = params => put('/platform/enterprisemembercheck/update',params);

/* 企业会员审核-审核*/
export const enterprisememberCheck  = params => put('/platform/check/update',params);

/* 企业会员积分管理 - 列表 */
export const enterprisememberintegralQueryList  = params => get('/platform/enterprisememberintegralQuery/list',params);

/* 企业会员积分管理 - 操作*/
export const enterprisememberintegralUpdate  = params => put('/platform/enterprisememberintegral/update',params);

/* ============== 企业会员管理 end================= */


/* ============== 分销中心 start================= */

/* 分销中心 - 分销员列表*/
export const distributionAllList  = params => get('/platform/distribution/allList',params);

/* 分销中心 - 返佣设置查看*/
export const backamountView  = params => get('/platform/back/amount/view',params);

/* 分销中心 - 返佣设置修改*/
export const backamountUpdate = params => put('/platform/back/amount/update',params);

/* 分销中心 - 下级用户列表*/
export const distributionAllListDetail = params => get('/platform/distribution/allList/detail',params);

/* ============== 分销中心 end================= */


/* ============== 数据统计 start================= */

/* 数据统计 - 业绩统计*/
export const performanceList = params => get('/platform/statistic/performance/list',params);

/* 数据统计 - 订单统计*/
export const ordercountList = params => get('/platform/statistic/ordercount/list',params);

/* 数据统计 - 充值统计*/
export const datacountrechargeList = params => get('/platform/statistic/datacount/recharge/list',params);

/* 数据统计 - 流量统计*/
export const datacountbussclickList = params => get('/platform/statistic/datacount/bussclick/list',params);

/* ============== 数据统计 end================= */


/* ============== 积分管理 start================= */

/* 积分管理 - 列表*/
export const integralrechargeAuditList = params => get('/platform/integral/acc/integralrecharge/audit/list',params);

/* 积分管理 - 审核*/
export const integralrechargeAuditUpdate = params => put('/platform/integral/acc/integralrecharge/audit/update',params);

/* 积分管理 - 提现审核列表*/
export const integraldrawList = params => get('/platform/integral/draw/list',params);

/* 积分管理 - 提现审核*/
export const integraldrawCheck = params => put('/platform/integral/draw/check',params);

/* 积分管理 - 登记*/
export const integraldrawRegister = params => put('/platform/integral/draw/register',params);

/* 积分管理 - 积分比例配置*/
export const integralpreconfList = params => get('/platform/integral/pre/conf/list',params);

/* 积分管理 - 积分比例添加配置*/
export const integralpreconfParams = params => get('/platform/integral/pre/conf/params',params);

/* 积分管理 - 积分比例修改*/
export const integralpreconfUpdate = params => put('/platform/integral/pre/conf/update',params);

/* 积分管理 - 积分比例添加*/
export const integralpreconfAdd = params => post('/platform/integral/pre/conf/add',params);

/* 积分管理 - 积分比例删除*/
export const integralpreconfDelete = params => Delete(`/platform/integral/pre/conf/delete/${params}`);

/* 积分管理 - 结算配置列表*/
export const integralsettlementconfList = params => get('/platform/integral/settlement/conf/list',params);

/* 积分管理 - 结算配置添加配置*/
export const integralsettlementconfParams = params => get('/platform//integral/settlement/conf/params',params);

/* 积分管理 - 结算配置编辑*/
export const integralsettlementconfUpdate = params => put('/platform/integral/settlement/conf/update',params);

/* 积分管理 - 结算配置添加*/
export const integralsettlementconfAdd = params => post('/platform/integral/settlement/conf/add',params);

/* 积分管理 - 结算配置删除*/
export const integralsettlementconfDelete = params => Delete(`/platform/integral/settlement/conf/delete/${params}`);

/* ============== 积分管理 end================= */


/* *******
********
********
********
********

企业会员API

*********
*********
************ */

/* ============== 员工管理 start================= */

/* 员工管理 - 员工列表*/
export const employeeList = params => get('/platform/employee/list',params);

/* 员工管理 - 编辑员工*/
export const employeeEditUpdate = params => put('/platform/employeeEdit/update',params);

/* 员工管理 - 移除员工*/
export const employeeUndock = params =>  put(`/platform/employeeUndock/${params}`);

/* 员工管理 - 下载模板*/
export const excelDownload = params =>  get('/platform/excel/download',params);

/* 员工管理 - 导入员工*/
export const excelExport = params =>  post('/platform/excel/export',params);

/* ============== 员工管理 end================= */


/* ============== 数据统计 start================= */

/* 数据统计 - 积分统计*/
export const integralList = params =>  get('/platform/memberdata/integral/list',params);

/* 数据统计 - 积分充值列表*/
export const integralrechargeList = params =>  get('/platform/acc/integralrecharge/list',params);

/* 数据统计 - 积分充值*/
export const integralrechargeUpdate = params =>  put('/platform/acc/integralrecharge/update',params);

/* 数据统计 - 积分充值余额*/
export const pointsoutBalance = params =>  get('/platform/acc/pointsout/balance',params);

/* 数据统计 - 积分充值余额*/
export const pointsoutUpdate = params =>  put('/platform/acc/pointsout/update',params);

/* 数据统计 - 积分充值下载模板*/
export const pointsoutDownload = params =>  get('/platform/acc/pointsout/download',params);

/* 数据统计 - 积分发放*/
export const pointsoutFafang = params =>  put('/platform/acc/pointsout/update',params);

/* ============== 数据统计 end================= */


/* ============== 首页管理 start================= */

/* 首页管理 - banner管理*/
export const qyBannerList = params =>  get('/qy/banner/list',params);

/* 首页管理 - 添加banner*/
export const qyBannerAdd = params =>  post('/qy/banner/add',params);

/* 首页管理 - 修改banner*/
export const qyBannerUpdate = params =>  put('/qy/banner/update',params);

/* 首页管理 - 删除banner*/
export const qyBannerDelete = params =>  Delete(`/qy/banner/delete/${params}`);

/* ============== 首页管理 end================= */
